const projectsInfo = [
  {
    id: 15,
    href: "https://blog.emilianolucero.info/",
    imgSrc: "blog.jpg",
    videoSrc: "/videos/blog.mp4",
    title: "I 🖤 my computer job",
    description:
      "Designed and developed a dynamic blog platform showcasing original writings, technical explorations, curated photography collections, handcrafted playlists, and more. Backend built with Payload CMS, frontend with Next.js, GSAP for animations, React Three Fiber for 3D elements, and Typescript for type safety.",
    tag1: "Next.js",
    tag2: "GSAP",
    tag3: "Typescript",
    colStart: 1,
    colSpan: 4,
    rowStart: 1,
  },
  {
    id: 13,
    href: "https://cutie-romero.vercel.app",
    imgSrc: "cutie.jpg",
    videoSrc: "/videos/cutie.mp4",
    title: "Cutie Romero",
    description:
      "Designed and developed a Three.js experience that explores the interaction between Twitter fandom and professional football players featuring interactive elements, animations, physics, and shaders. Built with Vanilla JavaScript, GSAP for animations, and Three.js. Model created with Blender.",
    tag1: "Three.js",
    tag2: "Javascript",
    tag3: "GSAP",
    colStart: 9,
    colSpan: 4,
    rowStart: 1,
  },
  {
    id: 12,
    href: "https://www.instagram.com/p/CnPnO8dgXFE/",
    imgSrc: "prato.jpg",
    videoSrc: "/videos/prato.mp4",
    title: "Archivo Prato",
    description:
      "This interactive installation was designed and developed in collaboration with my friend Virginia Molinari, utilizing her extensive photography archive. Built with JavaScript and Pixi.js, it incorporates shaders for visual effects and motion detection via a Kinect device. The project was exhibited at the Complejo Astronómico in Rosario, Argentina.",
    tag1: "Javascript",
    tag2: "Pixi.js",
    tag3: "Kinectron",
    colStart: 13,
    colSpan: 4,
    rowStart: 1,
  },
  {
    id: 11,
    href: "https://github.com/emilianoglucero/emilianolucero-portfolio",
    imgSrc: "portfolio.jpg",
    videoSrc: "/videos/portfolio.mp4",
    title: "Personal Portfolio Website",
    description:
      "Designed and developed a landing page for my portfolio (this site), built using React, Gatsby, p5.js for the background animation, GSAP for animations, and TailwindCSS for styling. The intro animation was created by the animator Inés Labarrère Ventroni.",
    tag1: "GSAP",
    tag2: "TailwindCSS",
    tag3: "Gatsby",
    colStart: 5,
    colSpan: 4,
    rowStart: 2,
  },
  {
    id: 14,
    href: "https://bebeto-pizza.vercel.app/nuncasepuedehacermasrapido.html",
    imgSrc: "nuncasepuedehacermasrapido.jpg",
    videoSrc: "/videos/nuncasepuedehacermasrapido.mp4",
    title: "Nunca se puede hacer más rápido",
    description:
      "Designed and developed an interactive project using React Three Fiber, GSAP animations, and custom shaders to showcase a printed zine I released in 2018. This digital experience merges motion graphics and 3D rendering to bring the zine's content to life in a dynamic, immersive way.",
    tag1: "React Three Fiber",
    tag2: "GSAP",
    tag3: "React.js",
    colStart: 13,
    colSpan: 4,
    rowStart: 2,
  },
  // {
  //   id: 13,
  //   href: "https://github.com/emilianoglucero/giphy-api-example",
  //   imgSrc: "giphy.jpg",
  //   title: "Giphy searcher",
  //   description:
  //     "A super super super simple gif searcher powered by Giphy. An excuse to do some Unit Testing.",
  //   tag1: "React",
  //   tag2: "UnitTesting",
  //   tag3: "Javascript",
  // },
  // {
  //   id: 10,
  //   href: "https://github.com/emilianoglucero/twitter-bot",
  //   imgSrc: "twitter.jpg",
  //   videoSrc: "",
  //   title: "Twitter Bot",
  //   description:
  //     "A Twitter bot with a melancholic personality, developed using JavaScript, Node.js, and Express. This bot generates and posts sad or introspective tweets, exploring the emotional range of automated social media interactions.",
  //   tag1: "Node.js",
  //   tag2: "Javascript",
  //   tag3: "Express",
  // },
  // {
  //   id: 11,
  //   href: "https://www.alphatrainingcenter.com.ar/",
  //   imgSrc: "alpha.jpg",
  //   title: "Alpha Training Center Dojo",
  //   description: "Landing page made for the Alpha Training Center Dojo.",
  //   tag1: "React",
  //   tag2: "Gatsby",
  //   tag3: "Sass",
  // },
  {
    id: 9,
    href: "https://bebeto-pizza.vercel.app/compraventa.html",
    imgSrc: "compraventa.jpg",
    videoSrc: "/videos/compraventa.mp4",
    title: "Compraventa",
    description:
      "Designed and developed a project that reveals the inner dynamics of a Telegram group, offering an observational lens into the interactions and exchanges within this digital marketplace.",
    tag1: "NetArt",
    tag2: "React.js",
    tag3: "",
    colStart: 1,
    colSpan: 4,
    rowStart: 3,
  },
  // {
  //   id: 7,
  //   href:
  //     "https://chrome.google.com/webstore/detail/me-encanta-todo-eso/lelgaanomejijmiolbncggicogpalmcb",
  //   imgSrc: "chrome.jpg",
  //   title: "Me encanta todo eso",
  //   description:
  //     "A Google Chrome plugin that transforms the Facebook Desktop UI by altering the HTML and CSS to automatically inflate each post's like count into a large number of 'Love' reactions. This playful extension critiques social media engagement metrics.",
  //   tag1: "Javascript",
  //   tag2: "ChromePlugin",
  //   tag3: "",
  // },
  {
    id: 6,
    href: "https://bebeto-pizza.vercel.app/screenshots.html",
    imgSrc: "screenshots.jpg",
    videoSrc: "/videos/screenshots.mp4",
    title: "Screenshots",
    description:
      "Designed and developed a 3D experience with Three.js to showcase a printed zine I created in 2019. This project merges digital and physical media, offering an interactive way to explore the zine's content in a virtual environment.",
    tag1: "Three.js",
    tag2: "Javascript",
    tag3: "",
    colStart: 9,
    colSpan: 4,
    rowStart: 3,
  },
  {
    id: 5,
    href: "https://bebeto-pizza.vercel.app/dibuja.html",
    imgSrc: "dibuja.jpg",
    videoSrc: "/videos/dibuja.mp4",
    title: "Colorea un empresario",
    description:
      "Designed and developed an interactive project inviting users to color illustrations of the richest Argentine businessmen. This playful yet thought-provoking piece encourages reflection on the figures shaping Argentina’s economy, blending art with social commentary.",
    tag1: "p5.js",
    tag2: "Node.js",
    tag3: "Google_Cloud_Storage",
    colStart: 5,
    colSpan: 4,
    rowStart: 5,
  },
  {
    id: 4,
    href: "https://bebeto-pizza.vercel.app/maradona.html",
    imgSrc: "maradona.jpg",
    videoSrc: "/videos/maradona.mp4",
    title: "Para el pueblo lo mejor",
    description:
      "Designed and developed an experimental and visually striking tribute to Diego Armando Maradona. 💕.",
    tag1: "Javascript",
    tag2: "p5.js",
    tag3: "",
    colStart: 9,
    colSpan: 4,
    rowStart: 5,
  },
  {
    id: 3,
    href: "https://bebeto-pizza.vercel.app/zarandraca.html",
    imgSrc: "zarandraca.jpg",
    videoSrc: "/videos/zarandraca.mp4",
    title: "El viaje de zarandraca",
    description:
      "Designed and developed a video game project dedicated to a close friend. Built with p5.js and Phaser.io, and inspired by the mechanics of Flappy Bird, it combines playful interactivity with a personal narrative, offering a nostalgic yet innovative gaming experience.",
    tag1: "Javascript",
    tag2: "Node.js",
    tag3: "p5.js",
    colStart: 13,
    colSpan: 4,
    rowStart: 5,
  },
  // {
  //   id: 3,
  //   href:
  //     "https://play.google.com/store/apps/details?id=com.lse.ar.rosario.art&hl=es&gl=US",
  //   imgSrc: "art.jpg",
  //   title: "Asociación Rosarina de Tenis App",
  //   description:
  //     "Mobile Hybrid App for The Asociación Rosarina de Tenis made with Apache Cordova.",
  //   tag1: "Javascript",
  //   tag2: "ApacheCordova",
  //   tag3: "Framework7",
  // },
  // {
  //   id: 2,
  //   href: "https://www.instagram.com/ar/532471514229195/",
  //   imgSrc: "instagram.jpg",
  //   videoSrc: "",
  //   title: "Bebeto Face Filter",
  //   description:
  //     "Designed and developed an Instagram face filter created using Spark AR Studio for a music project. This filter enhances user engagement by blending artistic design with augmented reality, offering an interactive and visually compelling experience directly on social media.",
  //   tag1: "Spark",
  //   tag2: "Instagram",
  //   tag3: "",
  // },
  {
    id: 1,
    href: "https://bebeto-pizza.vercel.app/voca.html",
    imgSrc: "voca.jpg",
    videoSrc: "/videos/voca.mp4",
    title: "Abrí la voki",
    description:
      "Designed and developed a Net Art project blending randomness with interactive technology, utilizing p5.js to access the camera and incorporate facial recognition models. The piece pushes the boundaries of user interaction and digital identity, dynamically transforming the viewer's face into part of the artwork in real-time.",
    tag1: "NetArt",
    tag2: "Javascript",
    tag3: "p5.js",
    colStart: 1,
    colSpan: 4,
    rowStart: 5,
  },
  {
    id: 8,
    href: "https://ateitiscorp.com/",
    imgSrc: "ateitis.jpg",
    videoSrc: "/videos/ateitis.mp4",
    title: "Ateitis Corp",
    description:
      "This project was developed in collaboration with my friend Alexis Boni. It features a landing page and e-commerce functionality built with React and Gatsby, using WordPress as the CMS and WooCommerce for the e-commerce platform.",
    tag1: "React.js",
    tag2: "E-commerce",
    tag3: "Wordpress",
    colStart: 13,
    colSpan: 4,
    rowStart: 6,
  },
  {
    id: 16,
    href: "https://www.instagram.com/p/B-8N5VHFE6B/",
    imgSrc: "virtual-festival.jpg",
    videoSrc: "/videos/virtual-festival.mp4",
    title: "Virtual Musical Festival",
    description:
      "Organized and ran a virtual musical festival during the pandemic, successfully hosting two editions entirely online. Utilized Mozilla Hubs as a virtual platform, hosted on AWS, and customized the design of the virtual environment to create an immersive and interactive experience for attendees. Managed all aspects of the festival including technical setup, design customization, and user experience.",
    tag1: "AWS",
    tag2: "Mozilla_Hubs",
    tag3: "",
    colStart: 5,
    colSpan: 4,
    rowStart: 6,
  },
]

export default projectsInfo
